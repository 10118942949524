import React from "react";

const OurVisionVectorLg = () => {
  return (
    <svg
      width="351"
      height="447"
      viewBox="0 0 351 447"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 306.38V211.698H0V306.38H8ZM33.9767 211.698V318.998H26.6131V211.698H33.9767ZM59.9535 331.721V211.698H53.2262V331.721H59.9535ZM85.9302 211.698V344.444H79.8393V211.698H85.9302ZM111.907 357.168V211.698H106.452V357.168H111.907ZM137.776 211.698V369.891H132.958V211.698H137.776ZM163.751 382.508V211.698H159.569V382.508H163.751ZM189.732 211.698V395.232H186.186V211.698H189.732ZM215.708 407.955V211.698H212.799V407.955H215.708ZM241.685 211.698V420.678H239.412V211.698H241.685ZM267.658 433.295V211.698H266.022V433.295H267.658ZM293.635 446.019V211.698H292.635V446.019H293.635Z"
        fill="#969996"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.66 0V241.213H106.66V0H105.66ZM132.095 12.5114V246.726H133.873V12.5114H132.095ZM158.427 252.346V24.9165H160.983V252.346H158.427ZM184.757 37.4279V257.859H188.09V37.4279H184.757ZM211.087 263.478V49.8329H215.198V263.478H211.087ZM237.419 62.3444V268.992H242.308V62.3444H237.419ZM263.747 274.612V74.7495H269.413V274.612H263.747ZM290.075 87.2606V280.125H296.519V87.2606H290.075ZM316.406 285.638V99.666H323.629V285.638H316.406ZM342.842 112.177V291.258H350.842V112.177H342.842Z"
        fill="#969996"
      />
    </svg>
  );
};

export default OurVisionVectorLg;

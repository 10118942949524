import React, { useLayoutEffect, useRef } from "react";
import * as ourStoryStyles from "./OurStory.module.scss";
import ourStoryImg from "../../../../assets/images/our-story-img.webp";
import OurStoryVector from "../../../OurStoryVector/OurStoryVector";

import { FadeInUPTextAnimationWithTrigger } from "../../../../../GSAPSnippets";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { StaticImage } from "gatsby-plugin-image";

gsap.registerPlugin(ScrollTrigger);

const OurStory = () => {
  const ourStorySectionRef = useRef();

  useLayoutEffect(() => {
    FadeInUPTextAnimationWithTrigger("OSText", "our-story-section");
  });

  return (
    <section
      ref={ourStorySectionRef}
      className={`es-universal-section our-story-section es-section__dark OSText ${ourStoryStyles.ourStorySection}`}
    >
      <div className={ourStoryStyles.ourStoryContent}>
        <div>
          <span className={`es-subtitle-text es-subtitle-text__gray`}>
            Our Story
          </span>
          <h3>
            We set out on a journey to bring sincere tech solutions to firms
            that needed them the most. We saw many prominent companies
            struggling with their product scalability, quality, and tech
            constraints.
          </h3>
          <p>
            There had to be a way forward. Only many companies fail to find that
            for themselves. So, we built a team of tech elites.
            <br></br>
            <br></br>
            &nbsp;endshift was born to help such businesses during their time of
            need. Complex technology demands crisp talent that can ensure
            long-term performance after all. Hence our commitment to working
            with firms that need the final fix for their business.
          </p>
        </div>
      </div>
      <div className={`${ourStoryStyles.img_wrapper}`}>
        <StaticImage
          src="../../../../assets/images/our-story-img.webp"
          alt="showcase Img"
          layout="fullWidth"
          placeholder="none"
        />
      </div>

      <OurStoryVector />
    </section>
  );
};

export default OurStory;

import React, { useLayoutEffect } from "react";
import * as ourValuesStyles from "./OurValues.module.scss";
import { FadeInUPTextAnimationWithTrigger } from "../../../../../GSAPSnippets";

const ourValuesGrid = [
  {
    title: "Growth for all",
    desc: `We make the journey rightfully fruitful for every person involved.`,
  },
  {
    title: "Precision",
    desc: `Getting to the core of the concern, finding the most fitting solution.`,
  },
  {
    title: "No fluff",
    desc: `Sincere solutions without any theatrics to inflate your bill or waste your time.`,
  },
  {
    title: "Quality",
    desc: `We deliver high-performing solutions extruded through a quality-oriented due process. `,
  },
];

const OurValues = () => {
  useLayoutEffect(() => {
    FadeInUPTextAnimationWithTrigger(
      "OValuesText-wrapper",
      "our-values-section"
    );
  });
  return (
    <section
      className={`our-values-section ${ourValuesStyles.ourValuesSection}`}
    >
      <div className="OValuesText-wrapper">
        <span className={`es-subtitle-text es-subtitle-text__gray`}>
          Our Values
        </span>

        <h3>The four cornerstones of our service.</h3>
        <div className={ourValuesStyles.ourValuesGrid}>
          {ourValuesGrid.map((item, index) => (
            <div className={ourValuesStyles.ourValuesGridItem} key={index}>
              <div className={ourValuesStyles.ourValuesGridItemContent}>
                <h4>{item.title}</h4>
                <p>{item.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurValues;

import React from "react";
import CTAButton from "../CTAButton/CTAButton";
import { Arrow } from "../SVGs/SVGs";
import * as contactUsStyles from "./ContactUsSection.module.scss";

const ContactUsSection = ({ data }) => {
  return (
    <section className={`es-section__red ${contactUsStyles.contactUsSection}`}>
      <div className={contactUsStyles.contactUsContent}>
        <div>
          <h2>{data.title}</h2>
          {data.desc && <p>{data.desc}</p>}
        </div>
        <div>
          <CTAButton
            type="button"
            size="btn-lg"
            text="Contact us"
            rounded={true}
            outlined={false}
            btnBG="es-btn-white"
            spacingClasses=""
            additionalClass="noHover"
            hasIcon={true}
            icon={<Arrow color="var(--redPink)" />}
            reverse={true}
            isLink={true}
            isShadow={false}
            href="/contact-us"
          />
        </div>
      </div>
    </section>
  );
};

export default ContactUsSection;

import React, { useLayoutEffect } from "react";
import * as ourVisionStyles from "./OurVision.module.scss";
import ourVisionImg from "../../.././../assets/images/our-vision.webp";
import OurVisionVectorSm from "../../../OurVisionVector/OurVisionVectorSm";
import OurVisionVectorLg from "../../../OurVisionVector/OurVisionVectorLg";

import { FadeInUPTextAnimation } from "../../../../../GSAPSnippets";

const OurVision = () => {
  useLayoutEffect(() => {
    FadeInUPTextAnimation("OVText-wrapper", 1.2);
  });

  return (
    <section
      className={`es-section__light our-vision-section ${ourVisionStyles.ourVisionSection}`}
    >
      <div className="OVText-wrapper">
        <div className={ourVisionStyles.ourVisionGrid}>
          <div className={ourVisionStyles.ourVisionGridItem}>
            <div
              className={`es-universal-section ${ourVisionStyles.ourVisionGridItemLeftText}`}
            >
              <span className={`es-subtitle-text`}>Our Vision</span>
              <h2>
                We are on a journey to resolving mind-boggling tech challenges
                with our industry-dominating tech ninjas.
              </h2>
            </div>
            <div className={`${ourVisionStyles.img_wrapper}`}>
              <img
                width="100%"
                height="100%"
                src={ourVisionImg}
                alt="ourVisionImg"
              />
            </div>
          </div>
          <div className={ourVisionStyles.ourVisionGridItem}>
            <div className={ourVisionStyles.ourVisionVectors}>
              <span
                className={`our-vision-vector-sm ${ourVisionStyles.ourVisionVector}`}
              >
                <OurVisionVectorSm />
              </span>
              <span
                className={`our-vision-vector ${ourVisionStyles.ourVisionVector}`}
              >
                <OurVisionVectorLg />
              </span>
            </div>
            <p>
              Our liaison with the finest brains in tech enables us to unlock
              challenges for clients who need nothing but the best. We focus on
              quality and precision with a future-ready approach in delivering
              every milestone of end-to-end development.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurVision;

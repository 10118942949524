import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const FadeInUPTextAnimation = (elem, delay) => {
  gsap.fromTo(
    `.${elem}`,
    {
      opacity: 0,
      y: 100,
    },
    {
      opacity: 1,
      y: 0,
      ease: "expo.out",
      stagger: {
        from: "start",
        amount: 0.7,
      },
      duration: 1.5,
      delay: delay,
    }
  );
};

export const FadeInUPTextAnimationWithTrigger = (elem, trigger) => {
  gsap.fromTo(
    `.${elem}`,
    {
      opacity: 0,
      y: 100,
    },
    {
      opacity: 1,
      y: 0,
      ease: "expo.out",
      stagger: {
        from: "start",
        amount: 0.7,
      },
      duration: 1.5,
      scrollTrigger: {
        trigger: `.${trigger}`,
        start: "top center",
        end: "top top",
      },
    }
  );
};
export const FadeInUPTextAnimationWithTrigger_ref = (elem, trigger) => {
  const q = gsap.utils.selector(trigger);

  gsap.fromTo(
    q(`.${elem}`),
    {
      opacity: 0,
      y: 100,
    },
    {
      opacity: 1,
      y: 0,
      ease: "expo.out",
      stagger: {
        from: "start",
        amount: 0.7,
      },
      duration: 1.5,
      scrollTrigger: {
        trigger: trigger,
        start: "top center",
        end: "top top",
        // markers: false,
      },
    }
  );
};
export const FadeInUPTextAnimationWithTriggerViewPort_ref = (
  el,
  trigger,
  start,
  delay
) => {
  const q = gsap.utils.selector(trigger);

  gsap.from(q(`.${el}`), {
    opacity: 0,
    y: 100,
    ease: "expo.out",
    delay: delay,
    stagger: {
      from: "start",
      amount: 0.7,
    },
    duration: 1.5,
    scrollTrigger: {
      trigger: trigger,
      start: `top ${start}`,
      end: "top top",
      // markers: false,
    },
  });
};
export const imgRevealWrapperTweenWithLoop = (overlay, img, trigger) => {
  const q = gsap.utils.selector(trigger);

  gsap.to(q(overlay), {
    y: "-105%",
    duration: 0.5,
    ease: "none",
    scrollTrigger: {
      trigger: trigger,
      start: "top bottom-=400",
      end: "top top",
      // markers: false,
    },
  });
  gsap.from(q(img), {
    scale: 1.3,
    opacity: 0,
    duration: 1,
    ease: "none",
    scrollTrigger: {
      trigger: trigger,
      start: "top bottom-=400",
      end: "top top",
    },
  });
};
export const imgRevealWrapperTween = (elem, trigger) => {
  gsap.from(elem, {
    y: "50%",
    opacity: 0,
    duration: 0.5,
    ease: "none",
    scrollTrigger: {
      trigger: `.${trigger}`,
      start: "top top+=100",
      end: "top top",
      // markers: false,
    },
  });
};

export const imgOverLayRevealTween = (elem, trigger) => {
  gsap.to(elem, {
    y: "-105%",
    delay: 0.5,
    duration: 1,
    ease: "none",
    scrollTrigger: {
      trigger: `.${trigger}`,
      start: "top top+=100",
      end: "top top",

      // markers: false,
    },
  });
};
export const imgRevealTween = (elem, trigger) => {
  gsap.from(elem, {
    scale: 1.3,
    delay: 1,
    opacity: 0,
    duration: 1.5,
    ease: "none",
    scrollTrigger: {
      trigger: `.${trigger}`,
      start: "top top+=100",
      end: "top top",

      // markers: false,
    },
  });
};

export const imgRevealWrapperTweenWithoutTrigger = (elem, delay) => {
  gsap.from(elem, {
    y: "5%",
    opacity: 0,
    duration: 0.5,
    ease: "none",
    delay: delay,
  });
};
export const imgOverLayRevealTweenWithoutTrigger = (elem, delay) => {
  gsap.to(elem, {
    y: "-105%",
    duration: 0.7,
    ease: "none",
    delay: delay,
  });
};
export const imgRevealTweenWithoutTrigger = (elem, delay) => {
  gsap.from(elem, {
    scale: 1.3,
    opacity: 0,
    duration: 1,
    ease: "none",
    delay: delay,
  });
};

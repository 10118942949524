import React from "react";

const OurVisionVectorSm = () => {
  return (
    <svg
      width="211"
      height="264"
      viewBox="0 0 211 264"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 181.099V125.133H0V181.099H8ZM23.2248 125.133V188.557H15.8612V125.133H23.2248ZM38.4476 196.078V125.133H31.7203V196.078H38.4476ZM53.6744 125.133V203.598H47.5835V125.133H53.6744ZM68.8991 211.119V125.133H63.4446V211.119H68.8991ZM84.0575 125.133V218.639H79.2393V125.133H84.0575ZM99.2823 226.097V125.133H95.1005V226.097H99.2823ZM114.511 125.133V233.618H110.966V125.133H114.511ZM129.734 241.139V125.133H126.825V241.139H129.734ZM144.959 125.133V248.659H142.686V125.133H144.959ZM160.181 256.117V125.133H158.545V256.117H160.181ZM175.404 263.638V125.133H174.404V263.638H175.404Z"
        fill="url(#paint0_linear_836_398)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.8867 0V142.579H64.8867V0H63.8867ZM79.3534 7.39534V145.838H81.1312V7.39534H79.3534ZM94.7593 149.159V14.728H97.3149V149.159H94.7593ZM110.163 22.1233V152.418H113.497V22.1233H110.163ZM125.567 155.74V29.4558H129.679V155.74H125.567ZM140.972 36.8513V158.999H145.86V36.8513H140.972ZM156.378 162.32V44.1838H162.044V162.32H156.378ZM171.78 51.579V165.579H178.224V51.579H171.78ZM187.186 168.838V58.9117H194.408V168.838H187.186ZM202.652 66.307V172.16H210.652V66.307H202.652Z"
        fill="url(#paint1_linear_836_398)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_836_398"
          x1="0"
          y1="211.91"
          x2="178.084"
          y2="211.91"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0864582" stopColor="#0066CC" />
          <stop offset="0.602083" stopColor="#EB3300" />
          <stop offset="0.966667" stopColor="#EB8D00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_836_398"
          x1="63.8867"
          y1="107.862"
          x2="212.895"
          y2="107.862"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0864582" stopColor="#0066CC" />
          <stop offset="0.602083" stopColor="#EB3300" />
          <stop offset="0.966667" stopColor="#EB8D00" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default OurVisionVectorSm;

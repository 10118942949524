import React, { useLayoutEffect } from "react";
import * as showcaseStyles from "./Showcase.module.scss";
import showcaseImg1 from "../../../../assets/images/showcase-1.webp";
import showcaseImg2 from "../../../../assets/images/showcase-2.webp";
import showcaseImg3 from "../../../../assets/images/showcase-3.webp";
import { FadeInUPTextAnimationWithTrigger } from "../../../../../GSAPSnippets";
import { StaticImage } from "gatsby-plugin-image";

const showcaseData = [
  {
    img: showcaseImg1,
  },
  {
    img: showcaseImg2,
  },
  {
    img: showcaseImg3,
  },
];

const Showcase = () => {
  useLayoutEffect(() => {
    FadeInUPTextAnimationWithTrigger("showcase-wrapper", "showcase-section");
  });

  return (
    <section
      className={`es-section__light showcase-section ${showcaseStyles.showcaseSection}`}
    >
      <div className={`showcase-wrapper ${showcaseStyles.showcaseGrid}`}>
        <div className={showcaseStyles.showcaseGridItem}>
          <div className={showcaseStyles.img_wrapper}>
            <StaticImage
              src="../../../../assets/images/showcase-1.webp"
              alt="showcase Img"
              layout="fullWidth"
              placeholder="none"
            />
          </div>
        </div>
        <div className={showcaseStyles.showcaseGridItem}>
          <div className={showcaseStyles.img_wrapper}>
            <StaticImage
              src="../../../../assets/images/showcase-2.webp"
              alt="showcase Img"
              layout="fullWidth"
              placeholder="none"
            />
          </div>
        </div>
        <div className={showcaseStyles.showcaseGridItem}>
          <div className={showcaseStyles.img_wrapper}>
            <StaticImage
              src="../../../../assets/images/showcase-3.webp"
              alt="showcase Img"
              layout="fullWidth"
              placeholder="none"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Showcase;

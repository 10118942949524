import React from "react";
import ContactUsSection from "../components/ContactUsSection/ContactUsSection";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import Leaders from "../components/sections/AboutUsPageSections/Leaders/Leaders";
import OurClientType from "../components/sections/AboutUsPageSections/OurClientType/OurClientType";
import OurCulture from "../components/sections/AboutUsPageSections/OurCulture/OurCulture";
import OurStory from "../components/sections/AboutUsPageSections/OurStory/OurStory";
import OurStyle from "../components/sections/AboutUsPageSections/OurStyle/OurStyle";
import OurValues from "../components/sections/AboutUsPageSections/OurValues/OurValues";
import OurVision from "../components/sections/AboutUsPageSections/OurVision/OurVision";
import Showcase from "../components/sections/AboutUsPageSections/Showcase/Showcase";
import TeamGallery from "../components/sections/AboutUsPageSections/TeamGallery/TeamGallery";
import { Helmet } from "react-helmet";

const data = {
  title: "We take our work seriously. Do you?",
};

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>Why choose us</title>
        <meta
          name="description"
          content="endshift is a solution of the last resort. It helps enterprises, small to large businesses, and startups to scale and remove any technological congestion."
        />
        <link rel="canonical" href="https://endshift.io/" />
      </Helmet>
      <Header dataColor="header-dark" />
      <main>
        <OurVision />
        <OurStory />
        <OurStyle />
        <Showcase />
        <OurClientType />
        <OurCulture />
        <Leaders />
        {/* <TeamGallery /> */}
        <OurValues />
        <ContactUsSection data={data} />
      </main>
      <Footer />
    </>
  );
};

export default AboutUs;

import React, { useLayoutEffect } from "react";

import ClientLogo1 from "../../../../assets/images/Bonairelogo.png";
import ClientLogo2 from "../../../../assets/images/CiraSync.svg";
import ClientLogo3 from "../../../../assets/images/dok32.svg";
import ClientLogo4 from "../../../../assets/images/itrezzo-logo.svg";
import ClientLogo5 from "../../../../assets/images/Smartboad.svg";
import * as ourClientTypeStyles from "./OurClientType.module.scss";
import { FadeInUPTextAnimationWithTrigger } from "../../../../../GSAPSnippets";

const OurClientType = () => {
  useLayoutEffect(() => {
    FadeInUPTextAnimationWithTrigger(
      "OCTText-wrapper",
      "our-client-type-section"
    );
  });

  return (
    <section
      className={`es-section__light our-client-type-section ${ourClientTypeStyles.ourClientTypeSection}`}
    >
      <div className="OCTText-wrapper">
        <div
          className={`es-universal-section ${ourClientTypeStyles.ourClientTypeText}`}
        >
          <span className={`es-subtitle-text`}>Our Client Type</span>
          <h3>
            Yes! We have a client type. We only work with like-minded businesses
            that want to last long-term.
          </h3>
          <p>
            We have worked with leading international
            <strong>
              SaaS products, DaaS products, cloud technologies, digital services
            </strong>
            , and more. They all had one thing in common: the ambition to
            succeed in the future and today. Our solutions are powered through
            integrity. No hit and run entry, no band-aid fixes. It’s about
            sophisticated tech after all.
          </p>
          <p>
            Don’t mind us being selective with our clients. We reserve our
            energies for firms that need us the most. Do you have the vision to
            last in the long game? Are you with us?
          </p>
        </div>
        <div className={ourClientTypeStyles.ourClientTypeGrid}>
          <div className={ourClientTypeStyles.ourClientTypeGridItem}>
            <img src={ClientLogo1} alt="smart board" loading="lazy" />
          </div>
          <div className={ourClientTypeStyles.ourClientTypeGridItem}>
            <img src={ClientLogo2} alt="cira sync" loading="lazy" />
          </div>
          <div className={ourClientTypeStyles.ourClientTypeGridItem}>
            <img src={ClientLogo3} alt="bonaire" loading="lazy" />
          </div>

          <div className={ourClientTypeStyles.ourClientTypeGridItem}>
            <img src={ClientLogo4} alt="dok 32" loading="lazy" />
          </div>
          <div className={ourClientTypeStyles.ourClientTypeGridItem}>
            <img src={ClientLogo5} alt="I trezzo" loading="lazy" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurClientType;

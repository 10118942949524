import React, { useLayoutEffect, useRef } from "react";
import * as ourStyleStyles from "./OurStyle.module.scss";
import { FadeInUPTextAnimationWithTrigger } from "../../../../../GSAPSnippets";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const OurStyle = () => {
  const ourStyleSectionRef = useRef();
  useLayoutEffect(() => {
    FadeInUPTextAnimationWithTrigger("OStyleText", "our-style-section");
  });

  return (
    <section
      ref={ourStyleSectionRef}
      className={`es-section__light our-style-section ${ourStyleStyles.ourStyleSection}`}
    >
      <div className="OStyleText">
        <h2>No one does it better</h2>
        <div className={ourStyleStyles.ourStyleInnerContent}>
          <span className={`es-subtitle-text `}>Our Style</span>
          <h4>
            We don’t solve simple problems, we simplify complex tech to perform
            at its best. Our approach to lean, future-proof, and reliable
            development makes us elite in the industry. We are boujee that way.
          </h4>
        </div>
      </div>
    </section>
  );
};

export default OurStyle;

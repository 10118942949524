import React, { useRef, useLayoutEffect } from "react";
import * as teamGalleryStyles from "./TeamGallery.module.scss";
import extraGallery0 from "../../../../assets/images/extra-gallery.png";
import extraGallery1 from "../../../../assets/images/extra-gallery-1.png";
import team from "../../../../assets/images/team-pic.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectCoverflow } from "swiper";

import "swiper/scss";
import "swiper/scss/pagination";
import "swiper/scss/effect-coverflow";

import { imgRevealWrapperTweenWithLoop } from "../../../../../GSAPSnippets";
import { Link } from "gatsby";

const teamGallery = [
  {
    img: team,
    name: "Taimoor Raza",
    designation: "Full Stack Developer",
    profiles: [
      {
        name: "linkedin",
        link: "/",
      },
      {
        name: "upwork",
        link: "/",
      },
    ],
  },
  {
    img: team,
    name: "Osama",
    designation: "Program Manager",
    profiles: [
      {
        name: "linkedin",
        link: "/",
      },
      {
        name: "upwork",
        link: "/",
      },
    ],
  },
  {
    img: team,
    name: "Ali Hasan",
    designation: "Product Manager",
    profiles: [
      {
        name: "upwork",
        link: "/",
      },
    ],
  },
  {
    img: team,
    name: "Arsalan Ahmad",
    designation: "Project Manager",
    profiles: [
      {
        name: "upwork",
        link: "/",
      },
    ],
  },
  {
    img: team,
    name: "Khunsha Javed",
    designation: "Content Branding",
    profiles: [
      {
        name: "linkedin",
        link: "/",
      },
    ],
  },
  {
    img: team,
    name: "Saad Waseem",
    designation: "Full Stack Engineer",
    profiles: [
      {
        name: "linkedin",
        link: "/",
      },
      {
        name: "upwork",
        link: "/",
      },
    ],
  },
];

const teamGalleryExtra = [
  {
    img: extraGallery0,
  },
  {
    img: extraGallery1,
  },
];

const TeamGallery = () => {
  const teamGalleryExtraRefs = useRef([]);
  teamGalleryExtraRefs.current = [];

  const addToteamGalleryExtraRefs = (el) => {
    if (el && !teamGalleryExtraRefs.current.includes(el)) {
      teamGalleryExtraRefs.current.push(el);
    }
  };

  useLayoutEffect(() => {
    teamGalleryExtraRefs.current.forEach((el) => {
      imgRevealWrapperTweenWithLoop(".img-reveal-overlay", ".reveal-img", el);
    });
  });

  return (
    <section className={`${teamGalleryStyles.teamGallerySection}`}>
      <div className={`es-team-gallery ${teamGalleryStyles.teamGalleryGrid}`}>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          spaceBetween={0}
          speed={400}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 0,
            modifier: 0,
            slideShadows: false,
          }}
          pagination={{
            type: "progressbar",
            el: ".es-tg-pagination",
            clickable: true,
          }}
          breakpoints={{
            0: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3.8,
            },
            1200: {
              slidesPerView: 4,
            },
          }}
          modules={[EffectCoverflow, Pagination]}
          onSlideChange={() => console.log("slide change")}
        >
          {teamGallery.map((item, index) => (
            <SwiperSlide key={index}>
              <div
                className={`es-tg-item ${teamGalleryStyles.teamGalleryGridItem}`}
              >
                <div
                  className={`es-tg-img ${teamGalleryStyles.teamGalleryGridItemImg}`}
                >
                  <img src={team} alt="" />
                </div>
                <div
                  className={`es-tg-text ${teamGalleryStyles.teamGalleryGridItemText}`}
                >
                  <p> {item.designation}</p>
                  <h4>{item.name}</h4>
                  <ul>
                    {item.profiles?.map((profile, profIndex) => (
                      <li key={profIndex}>
                        <Link to={profile.link}>{profile.name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="es-tg-pagination-wrapper">
          <div className="es-tg-pagination"></div>
        </div>
      </div>
      <div className={teamGalleryStyles.extraGallery}>
        {teamGalleryExtra.map((item, index) => (
          <div
            ref={addToteamGalleryExtraRefs}
            key={index}
            className={teamGalleryStyles.extraGalleryImg}
          >
            <div
              className={`img-reveal-wrapper ${teamGalleryStyles.img_wrapper}`}
            >
              <div className="img-reveal-overlay"></div>
              <img className="reveal-img" src={item.img} alt="showcase Img" />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TeamGallery;

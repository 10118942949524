import React from "react";

const OurStoryVector = () => {
  return (
    <>
      <svg
        width="921"
        height="798"
        viewBox="0 0 921 798"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M935.94 10.6356C900.834 88.7547 812.453 129.104 755.034 202.295C696.095 277.722 714.478 294.227 635.632 396.73C585.842 461.575 367.891 573.906 306.539 630.478C232.849 698.672 222.003 760.178 142.549 819.32C95.1121 854.57 47.9093 889.586 0.471985 924.837"
          stroke="url(#paint0_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M948.245 22.9427C924.997 74.8656 877.798 110.355 832.173 149.767C809.478 169.356 787.266 189.896 768.283 214.136C709.339 289.09 726.547 306.769 647.8 407.022C632.098 427.026 600.91 450.806 563.813 476.195C485.024 529.893 380.71 590.955 330.836 632.226C326.719 635.627 322.957 638.911 319.549 642.08C259.615 698.191 240.747 749.796 192.364 799.135C181.678 810.06 169.45 820.854 155.208 831.512C107.89 866.882 60.3331 902.013 12.8958 937.263"
          stroke="url(#paint1_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M960.553 35.2486C937.421 86.8171 890.577 122.191 845.072 161.722C822.611 181.076 800.398 201.617 781.295 225.737C722.583 299.983 738.502 319.19 659.735 417.074C643.793 436.839 612.486 460.5 575.504 485.534C496.121 539.109 393.592 601.015 343.491 643.469C339.374 646.869 335.612 650.154 332.323 653.442C273.102 709.796 253.406 761.513 205.023 810.851C194.337 821.777 182.229 832.69 167.986 843.348C120.553 879.072 72.9963 914.203 25.4395 949.334"
          stroke="url(#paint2_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M972.858 47.5553C949.726 99.1238 903.236 134.382 857.965 173.679C835.505 193.033 813.646 213.458 794.424 237.459C736.062 311.115 750.452 331.613 671.904 427.366C655.608 447.246 624.177 470.313 587.31 494.993C507.099 548.68 406.589 611.196 356.262 654.832C352.264 658.351 348.502 661.636 345.094 664.805C286.701 721.048 266.176 772.875 217.443 822.804C206.757 833.729 194.649 844.642 180.406 855.3C133.093 891.144 85.4162 926.156 37.5091 961.876"
          stroke="url(#paint3_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M985.284 59.9811C962.267 111.195 916.016 146.693 870.861 185.635C848.755 204.873 826.542 225.414 807.555 249.18C749.423 322.127 762.404 344.034 683.955 437.538C667.185 457.414 635.87 480.127 599.119 504.452C518.314 558.015 419.588 621.376 369.034 666.195C365.036 669.715 361.274 672.999 357.985 676.288C300.305 732.773 278.953 784.712 230.219 834.64C219.298 845.8 207.19 856.713 193.067 867.491C145.638 903.689 97.9615 938.701 49.9349 974.302"
          stroke="url(#paint4_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M997.591 72.2872C974.689 123.147 928.558 158.764 883.757 197.591C861.651 216.829 839.793 237.254 820.686 260.901C762.785 333.139 774.237 356.336 696.007 447.709C678.883 467.701 647.443 489.821 610.808 513.791C529.41 567.231 432.468 631.436 381.687 677.438C377.689 680.957 374.046 684.362 370.758 687.65C313.671 744.259 291.49 796.31 242.876 846.357C231.955 857.517 219.847 868.43 205.725 879.208C158.18 915.76 110.384 950.652 62.3575 986.253"
          stroke="url(#paint5_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1009.89 84.5942C987.226 135.219 941.449 170.721 896.649 209.548C874.543 228.786 852.92 248.975 833.813 272.622C776.263 344.271 785.95 368.994 708.054 457.882C690.691 477.635 659.013 499.515 622.612 523.251C540.621 576.567 445.463 641.617 394.455 688.801C390.577 692.44 386.934 695.844 383.645 699.133C327.152 755.865 304.262 808.147 255.648 858.194C244.727 869.355 232.384 880.503 218.381 891.4C170.956 928.072 123.041 962.844 74.5443 998.915"
          stroke="url(#paint6_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1022.2 96.8999C999.418 147.405 954.115 182.911 909.55 221.503C887.679 240.506 865.82 260.931 846.948 284.343C789.509 355.164 797.788 381.295 720.11 468.053C702.393 487.921 670.71 509.328 634.306 532.59C551.721 585.783 458.462 651.322 406.758 700.159C402.88 703.798 399.237 707.202 395.948 710.491C340.168 767.466 316.45 819.859 267.601 870.142C256.445 881.537 244.457 892.569 230.334 903.347C182.674 940.254 134.878 975.145 86.382 1011.22"
          stroke="url(#paint7_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1034.51 109.206C1011.96 159.477 966.656 194.982 922.445 233.459C900.574 252.462 879.07 272.772 860.079 296.064C802.99 366.296 809.385 393.833 732.161 478.225C713.97 498.089 682.284 519.022 646.114 542.049C562.936 595.118 471.461 661.502 419.53 711.522C415.652 715.161 412.129 718.684 408.84 721.973C353.888 778.837 329.107 831.576 280.378 881.978C269.341 893.492 257.233 904.405 243.23 915.302C195.689 952.329 147.539 987.337 99.0423 1023.41"
          stroke="url(#paint8_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1046.94 121.631C1024.5 171.547 979.673 207.057 935.462 245.533C913.826 264.302 892.321 284.611 873.33 307.904C816.472 377.426 821.104 406.488 744.568 488.281C725.903 508.141 694.213 528.6 658.278 551.392C574.152 604.453 484.816 671.566 432.658 722.768C428.9 726.527 425.257 729.931 422.087 733.339C367.728 790.327 342.239 843.296 293.509 893.698C282.473 905.213 270.13 916.361 256.246 927.377C208.59 964.758 160.44 999.765 111.824 1035.72"
          stroke="url(#paint9_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1059.24 133.939C1036.92 183.5 992.211 219.13 948.354 257.491C926.837 276.379 905.568 296.453 886.458 319.626C829.831 388.44 832.814 418.672 756.616 498.454C737.597 518.429 705.783 538.295 669.963 560.733C585.244 613.671 497.808 681.274 445.308 734.012C441.549 737.771 438.026 741.295 434.856 744.703C381.091 801.814 355.008 854.66 306.163 905.416C295.127 916.931 282.784 928.079 268.9 939.095C221.363 976.596 173.094 1011.48 124.008 1047.9"
          stroke="url(#paint10_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1071.55 146.244C1049.46 195.571 1004.87 231.32 961.251 269.446C939.735 288.334 918.466 308.408 899.59 331.346C843.194 399.451 844.294 431.089 768.669 508.625C749.176 528.596 717.477 548.108 681.773 570.191C596.461 623.005 510.689 691.333 458.082 745.374C454.323 749.133 450.8 752.656 447.631 756.065C394.458 813.299 367.666 866.376 318.587 917.368C307.315 929.117 295.092 940.384 281.443 951.166C233.791 989.02 185.521 1023.91 136.436 1060.33"
          stroke="url(#paint11_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1083.85 158.551C1061.65 207.759 1017.53 243.511 974.146 281.402C952.865 300.055 931.595 320.13 912.6 342.949C856.319 410.7 855.416 443.623 780.6 518.679C760.633 538.646 728.93 557.684 693.341 579.413C607.436 632.103 523.448 701.275 470.614 756.499C466.975 760.377 463.452 763.9 460.282 767.309C407.938 824.432 380.202 877.975 331.123 928.966C319.851 940.716 307.628 951.983 293.744 963C245.977 1001.21 197.588 1035.98 148.502 1072.4"
          stroke="url(#paint12_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1096.16 170.858C1074.19 219.83 1030.31 255.348 987.042 293.359C965.76 312.012 944.845 331.971 925.731 354.67C869.681 421.712 866.775 455.921 792.651 528.85C772.33 548.933 740.268 567.613 705.15 588.872C618.531 641.319 536.563 711.101 483.386 767.861C479.747 771.739 476.343 775.382 473.174 778.791C421.543 836.157 392.86 889.692 343.899 940.802C332.628 952.552 320.404 963.82 306.64 974.956C258.992 1013.28 210.483 1047.93 160.928 1084.82"
          stroke="url(#paint13_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1108.59 183.284C1086.73 231.902 1043.09 267.659 1000.06 305.434C979.009 323.852 957.859 344.047 938.979 366.511C883.16 432.844 878.371 468.459 804.82 539.142C784.024 559.221 751.959 577.426 716.956 598.331C629.744 650.655 549.44 721.162 496.156 779.225C492.517 783.103 489.113 786.746 486.063 790.273C435.025 847.763 405.634 901.528 356.674 952.639C345.402 964.389 332.944 975.891 319.179 987.027C271.416 1025.71 222.908 1060.36 173.233 1097.13"
          stroke="url(#paint14_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1120.9 195.589C1099.16 243.852 1055.75 279.848 1012.96 317.389C991.91 335.807 971.115 355.885 952.116 378.23C896.647 443.974 889.735 480.755 816.877 549.312C795.608 569.387 763.658 587.238 728.77 607.788C640.73 660.224 562.56 730.986 508.934 790.585C505.415 794.583 502.011 798.226 498.841 801.634C448.397 859.248 418.177 913.125 369.101 964.59C357.595 976.574 345.372 987.841 331.727 999.097C284.083 1037.9 235.22 1072.66 185.664 1109.55"
          stroke="url(#paint15_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1133.2 207.895C1111.7 255.924 1068.53 291.685 1025.85 329.346C1004.92 347.883 984.244 367.607 965.245 389.951C910.007 454.987 901.092 493.054 828.927 559.484C807.183 579.555 775.23 596.932 740.342 617.482C651.589 669.675 575.323 741.401 521.235 802.418C517.716 806.416 514.312 810.059 511.262 813.586C461.411 871.323 430.716 925.197 381.406 976.897C369.899 988.881 357.561 1000.5 344.032 1011.4C296.272 1050.56 247.409 1085.32 197.499 1122.33"
          stroke="url(#paint16_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1145.51 220.202C1123.88 268.112 1081.19 303.876 1038.74 341.302C1018.05 359.604 997.371 379.329 978.372 401.674C923.365 466 912.328 505.234 841.094 569.777C818.877 589.843 786.92 606.746 752.147 626.942C662.801 679.011 588.199 751.462 534.004 813.781C530.485 817.779 527.2 821.542 523.915 825.304C474.892 882.93 443.255 937.269 393.944 988.969C382.438 1000.95 370.099 1012.57 356.454 1023.83C308.815 1063.11 259.832 1097.75 209.687 1134.99"
          stroke="url(#paint17_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1157.81 232.509C1136.42 280.184 1093.85 316.068 1051.64 353.258C1030.95 371.561 1010.38 391.405 991.501 413.395C936.844 477.132 923.686 517.532 853.145 579.949C830.454 600.011 798.492 616.441 763.954 636.402C674.015 688.347 601.193 761.169 546.775 825.145C543.256 829.143 539.971 832.905 536.806 836.787C488.376 894.536 455.91 948.986 406.719 1000.81C395.332 1012.91 382.874 1024.41 369.229 1035.67C321.474 1075.3 272.492 1109.94 222.227 1147.06"
          stroke="url(#paint18_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1170.24 244.935C1148.96 292.255 1106.51 328.259 1064.65 365.334C1044.2 383.402 1023.64 403.245 1004.75 425.236C950.327 488.264 934.811 530.065 865.551 590.006C842.152 610.299 810.42 626.02 775.998 645.626C685.346 697.329 614.309 770.994 559.784 836.273C556.384 840.39 553.099 844.152 549.814 847.915C501.978 905.787 468.803 960.469 419.377 1012.52C407.755 1024.86 395.297 1036.36 381.771 1047.74C333.781 1087.6 284.799 1122.25 234.419 1159.73"
          stroke="url(#paint19_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1182.54 257.242C1161.39 304.207 1119.4 340.215 1077.55 377.29C1057.09 395.358 1036.89 415.086 1017.88 436.957C963.689 499.276 946.05 542.244 877.602 600.178C853.729 620.467 821.994 635.714 787.807 655.086C696.561 706.664 627.189 781.055 572.556 847.635C569.156 851.752 565.871 855.515 562.706 859.397C515.344 917.274 481.58 972.305 432.154 1024.36C420.532 1036.7 408.193 1048.32 394.548 1059.58C346.678 1099.56 297.576 1134.09 246.961 1171.8"
          stroke="url(#paint20_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1194.85 269.548C1173.93 316.278 1132.07 352.406 1090.45 389.246C1070.22 407.079 1049.78 427.042 1031.01 448.677C977.17 510.408 957.29 554.423 889.655 610.349C865.188 630.515 833.568 645.408 799.615 664.544C707.657 715.88 640.303 790.881 585.328 858.998C581.928 863.115 578.763 866.997 575.598 870.879C529.064 928.644 494.237 984.021 444.93 1036.2C433.309 1048.53 420.735 1060.39 407.444 1071.53C359.458 1111.87 310.356 1146.4 259.622 1183.99"
          stroke="url(#paint21_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1207.16 281.854C1186.11 328.465 1144.61 364.477 1103.34 401.202C1083.24 419.154 1063.03 438.883 1044.02 460.28C990.41 521.301 968.408 566.482 901.585 620.402C876.644 640.563 845.14 655.102 811.183 673.765C718.632 724.977 652.823 800.583 597.86 870.122C594.58 874.358 591.295 878.12 588.25 882.122C542.309 940.011 507.007 995.385 457.231 1048.03C445.374 1060.6 433.036 1072.22 419.63 1083.72C371.763 1124.18 322.307 1158.82 271.338 1196.64"
          stroke="url(#paint22_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1219.58 294.28C1198.78 340.656 1157.62 376.552 1116.36 413.277C1096.25 431.229 1076.28 450.723 1057.28 472.12C1003.89 532.432 979.648 578.661 913.756 630.693C888.342 650.851 856.834 664.915 823.112 683.343C729.847 734.312 665.823 810.762 610.753 881.603C607.707 885.605 604.307 889.722 601.262 893.724C555.914 951.736 519.785 1007.22 470.128 1059.98C458.272 1072.56 445.698 1084.41 432.527 1095.67C384.545 1136.49 335.088 1171.13 284.119 1208.95"
          stroke="url(#paint23_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1231.89 306.586C1211.2 352.608 1170.16 388.624 1129.25 425.234C1109.38 442.951 1089.41 462.444 1070.41 483.841C1017.37 543.565 990.768 590.72 925.808 640.865C899.8 660.899 868.407 674.609 834.801 692.683C740.823 743.409 678.464 820.584 623.406 892.847C620.36 896.848 616.96 900.966 613.914 904.967C569.16 963.103 532.322 1018.82 482.665 1071.58C470.809 1084.16 458.355 1096.13 444.949 1107.63C396.851 1148.79 347.51 1183.08 296.306 1221.14"
          stroke="url(#paint24_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1244.2 318.892C1223.62 364.559 1182.82 400.815 1142.15 437.189C1122.28 454.906 1102.43 474.519 1083.54 495.561C1030.5 554.811 1002.01 602.899 937.86 651.037C911.378 671.067 879.981 684.303 846.61 702.141C752.039 752.744 691.579 830.409 635.825 904.324C632.779 908.326 629.498 912.563 626.452 916.564C582.292 974.823 544.745 1030.77 495.088 1083.53C483.231 1096.11 470.777 1108.08 457.371 1119.58C409.393 1160.86 359.817 1195.39 308.259 1233.56"
          stroke="url(#paint25_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1256.5 331.199C1236.16 376.631 1195.48 413.006 1155.04 449.145C1135.41 466.628 1115.56 486.24 1096.67 507.283C1043.86 565.824 1013.36 614.723 949.91 661.209C923.07 680.88 891.553 693.997 858.297 711.481C763.014 761.841 704.218 840.231 648.476 915.568C645.55 919.689 642.269 923.925 639.223 927.927C595.536 986.19 557.281 1042.37 507.509 1095.49C495.417 1108.29 482.963 1120.27 469.911 1131.65C421.818 1173.29 372.122 1207.69 320.564 1245.87"
          stroke="url(#paint26_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1268.81 343.505C1248.35 388.818 1208.26 424.842 1167.94 461.101C1148.31 478.584 1128.81 498.082 1109.8 519.005C1057.34 576.956 1024.48 626.783 961.96 671.381C934.526 690.929 903.126 703.691 870.105 720.941C774.228 771.177 716.977 850.172 661.247 926.931C658.321 931.052 655.04 935.288 652.114 939.409C609.255 997.561 570.056 1054.2 520.284 1107.32C508.192 1120.13 495.738 1132.11 482.451 1143.72C434.477 1185.48 384.663 1219.76 332.989 1258.3"
          stroke="url(#paint27_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1281.23 355.931C1261.01 401.01 1221.04 437.153 1180.95 473.177C1161.44 490.779 1142.06 509.922 1123.05 530.845C1070.82 588.088 1035.6 638.843 974.13 681.673C946.103 701.097 915.053 713.27 882.032 730.52C785.442 780.513 729.976 860.353 674.138 938.413C671.212 942.534 668.051 946.89 665.006 950.892C622.74 1009.17 582.947 1065.69 533.06 1119.16C521.087 1132.09 508.399 1144.3 495.347 1155.68C447.258 1197.79 397.443 1232.08 345.415 1270.72"
          stroke="url(#paint28_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1293.54 368.237C1273.43 412.961 1233.7 449.344 1193.85 485.133C1174.57 502.5 1155.07 521.997 1136.18 542.566C1084.3 599.219 1046.96 650.666 986.419 691.609C957.918 711.03 926.864 722.728 893.958 739.624C796.89 789.139 742.853 869.939 687.028 949.421C684.102 953.542 680.941 957.898 678.014 962.019C636.342 1020.42 595.841 1077.17 545.953 1130.64C533.981 1143.57 521.292 1155.78 508.24 1167.16C460.155 1209.75 410.101 1243.79 358.073 1282.44"
          stroke="url(#paint29_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1305.85 380.543C1285.85 424.912 1246.48 461.18 1206.75 497.088C1187.47 514.455 1168.21 533.718 1149.31 554.286C1097.67 610.231 1058.31 662.49 998.47 701.78C969.61 720.842 938.436 732.422 905.766 749.082C807.985 798.354 755.613 879.879 699.8 960.783C696.993 965.023 693.832 969.379 690.906 973.5C649.708 1031.9 608.617 1089 558.495 1142.71C546.287 1155.87 533.833 1167.85 520.666 1179.58C472.7 1222.29 422.412 1256.57 370.264 1295.1"
          stroke="url(#paint30_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1318.15 392.85C1298.16 437.219 1259.14 473.371 1219.64 509.045C1200.6 526.177 1181.34 545.439 1162.32 565.888C1110.91 621.124 1069.55 674.196 1010.52 711.953C980.948 730.772 950.009 742.117 917.454 758.422C819.08 807.571 768.253 889.702 712.452 972.027C709.645 976.268 706.485 980.623 703.678 984.864C663.073 1043.39 621.154 1100.6 571.151 1154.43C558.944 1167.59 546.255 1179.8 533.323 1191.3C485.122 1234.25 435.068 1268.29 382.805 1307.17"
          stroke="url(#paint31_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1330.46 405.157C1310.58 449.172 1271.8 485.563 1232.53 521.001C1213.49 538.134 1194.35 557.516 1175.45 577.611C1124.38 632.257 1080.78 685.901 1022.57 722.126C992.403 740.821 961.579 751.811 929.259 767.882C830.172 816.788 781.009 899.644 725.22 983.39C722.414 987.631 719.372 992.106 716.446 996.227C676.435 1054.88 633.807 1112.32 583.804 1166.15C571.596 1179.31 559.027 1191.64 545.86 1203.37C497.778 1246.44 447.49 1280.72 394.873 1319.71"
          stroke="url(#paint32_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1342.89 417.582C1323.24 461.362 1284.46 497.752 1245.55 533.076C1226.51 550.208 1207.6 569.355 1188.7 589.45C1137.99 643.507 1092.26 697.844 1034.74 732.416C1004.22 750.753 973.277 761.623 941.072 777.34C841.273 826.002 793.54 909.818 737.998 994.752C735.191 998.992 732.384 1003.23 729.343 1007.71C689.805 1066.36 646.469 1124.04 596.585 1177.98C584.143 1191.38 571.573 1203.71 558.761 1215.33C510.564 1258.75 460.156 1292.91 407.538 1331.9"
          stroke="url(#paint33_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1355.19 429.889C1335.66 473.315 1297.36 509.71 1258.45 545.033C1239.76 562.05 1220.85 581.197 1201.83 601.172C1151.35 654.521 1103.49 709.55 1046.79 742.589C1015.91 760.568 984.848 771.319 952.878 786.8C852.601 834.985 806.297 919.761 750.768 1006.12C747.961 1010.36 745.274 1014.72 742.232 1019.19C703.288 1077.97 659.478 1135.64 609.124 1190.06C596.682 1203.45 584.113 1215.78 571.3 1227.4C522.988 1271.17 472.695 1304.98 419.843 1344.21"
          stroke="url(#paint34_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1367.5 442.194C1348.09 485.266 1309.9 521.78 1271.35 556.988C1252.66 574.005 1233.98 592.917 1214.96 612.892C1164.71 665.532 1115.08 721.138 1058.85 752.76C1027.25 770.495 996.424 781.012 964.689 796.258C863.698 844.2 819.06 929.7 763.307 1017.71C760.62 1022.07 757.813 1026.31 754.891 1030.91C716.421 1089.69 672.021 1147.71 621.668 1202.13C609.226 1215.52 596.656 1227.85 583.728 1239.83C535.535 1283.72 485.008 1317.76 431.92 1357.22"
          stroke="url(#paint35_linear_608_302)"
          strokeMiterlimit="10"
        />
        <path
          d="M1379.8 454.502C1360.39 497.573 1322.56 533.972 1284.24 568.945C1216.53 630.438 1147.42 721.534 1071.13 762.697C1039.18 780.074 1008.23 790.471 976.611 805.363C874.908 853.062 831.933 939.288 776.193 1028.72C736.696 1092.11 688.546 1154.84 634.438 1213.49C574.931 1278.01 511.67 1319.05 444.456 1368.82"
          stroke="url(#paint36_linear_608_302)"
          strokeMiterlimit="10"
        />
        <defs>
          <linearGradient
            id="paint0_linear_608_302"
            x1="462.476"
            y1="461.754"
            x2="517.906"
            y2="517.183"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_608_302"
            x1="474.805"
            y1="474.086"
            x2="528.913"
            y2="528.194"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_608_302"
            x1="487.139"
            y1="486.417"
            x2="539.941"
            y2="539.219"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_608_302"
            x1="499.469"
            y1="498.749"
            x2="550.974"
            y2="550.253"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_608_302"
            x1="511.8"
            y1="511.08"
            x2="562.018"
            y2="561.298"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_608_302"
            x1="524.133"
            y1="523.411"
            x2="573.073"
            y2="572.352"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_608_302"
            x1="536.46"
            y1="535.743"
            x2="584.134"
            y2="583.417"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_608_302"
            x1="548.796"
            y1="548.074"
            x2="595.213"
            y2="594.491"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_608_302"
            x1="561.128"
            y1="560.406"
            x2="606.297"
            y2="605.575"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_608_302"
            x1="573.461"
            y1="572.736"
            x2="617.392"
            y2="616.667"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_608_302"
            x1="585.789"
            y1="585.069"
            x2="628.492"
            y2="627.772"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_608_302"
            x1="598.122"
            y1="597.399"
            x2="639.606"
            y2="638.883"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_608_302"
            x1="610.453"
            y1="609.732"
            x2="650.728"
            y2="650.007"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_608_302"
            x1="622.784"
            y1="622.063"
            x2="661.875"
            y2="661.154"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_608_302"
            x1="635.114"
            y1="634.395"
            x2="673.072"
            y2="672.353"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_608_302"
            x1="647.451"
            y1="646.725"
            x2="684.32"
            y2="683.594"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_608_302"
            x1="659.781"
            y1="659.056"
            x2="695.6"
            y2="694.875"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_608_302"
            x1="671.702"
            y1="670.981"
            x2="706.913"
            y2="706.192"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_608_302"
            x1="683.047"
            y1="682.328"
            x2="718.259"
            y2="717.54"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint19_linear_608_302"
            x1="694.296"
            y1="693.576"
            x2="729.637"
            y2="728.917"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint20_linear_608_302"
            x1="705.455"
            y1="704.735"
            x2="741.041"
            y2="740.32"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint21_linear_608_302"
            x1="716.536"
            y1="715.815"
            x2="752.472"
            y2="751.75"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint22_linear_608_302"
            x1="727.548"
            y1="726.828"
            x2="763.927"
            y2="763.207"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint23_linear_608_302"
            x1="738.503"
            y1="737.781"
            x2="775.411"
            y2="774.689"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint24_linear_608_302"
            x1="749.406"
            y1="748.685"
            x2="786.921"
            y2="786.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint25_linear_608_302"
            x1="760.266"
            y1="759.543"
            x2="798.461"
            y2="797.738"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint26_linear_608_302"
            x1="771.084"
            y1="770.363"
            x2="810.029"
            y2="809.308"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint27_linear_608_302"
            x1="781.87"
            y1="781.151"
            x2="821.631"
            y2="820.911"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint28_linear_608_302"
            x1="792.628"
            y1="791.908"
            x2="833.269"
            y2="832.549"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint29_linear_608_302"
            x1="803.362"
            y1="802.64"
            x2="844.948"
            y2="844.226"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint30_linear_608_302"
            x1="814.071"
            y1="813.349"
            x2="856.667"
            y2="855.945"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint31_linear_608_302"
            x1="824.76"
            y1="824.039"
            x2="868.434"
            y2="867.713"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint32_linear_608_302"
            x1="835.427"
            y1="834.71"
            x2="880.266"
            y2="879.549"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint33_linear_608_302"
            x1="846.088"
            y1="845.364"
            x2="892.141"
            y2="891.418"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint34_linear_608_302"
            x1="856.726"
            y1="856.007"
            x2="904.069"
            y2="903.349"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint35_linear_608_302"
            x1="867.332"
            y1="866.608"
            x2="916.117"
            y2="915.394"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint36_linear_608_302"
            x1="877.972"
            y1="877.251"
            x2="928.504"
            y2="927.783"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default OurStoryVector;

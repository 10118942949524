import React, { useLayoutEffect, useRef } from "react";
import * as leadersStyles from "./Leaders.module.scss";
import AtifSignature from "../../../AtifSignature/AtifSignature";
import atifImg from "../../../../assets/images/atif.webp";
import qaziImg from "../../../../assets/images/qazi.png";
import QaziSignature from "../../../QaziSignature/QaziSignature";
import { FadeInUPTextAnimationWithTrigger } from "../../../../../GSAPSnippets";
import { StaticImage } from "gatsby-plugin-image";

const leadersData = [
  {
    desc: `Founder of endshift. More than a decade of experience. Experience in 4 continents, more than 40 companies.`,
    name: "Atif",
    sign: <AtifSignature />,
    img: atifImg,
  },
];

const Leaders = () => {
  useLayoutEffect(() => {
    FadeInUPTextAnimationWithTrigger("MTLText-wrapper", "leaders-section");
  });
  return (
    <section className={`leaders-section ${leadersStyles.leadersSection}`}>
      <div className="MTLText-wrapper">
        <h3>
          Meet &nbsp;
          <br></br>
          the leader
        </h3>
        <div className={`es-universal-section ${leadersStyles.leadersInfo}`}>
          {leadersData.map((item, index) => (
            <div className={leadersStyles.leadersInfoItem} key={index}>
              <div className={leadersStyles.leadersItemText}>
                <p>{item.desc}</p>
                <span>{item.name} </span>
                {item.sign}
              </div>
              <div className={leadersStyles.leadersItemImg}>
                <div className={leadersStyles.img_wrapper}>
                  <StaticImage
                    src="../../../../assets/images/atif.webp"
                    alt="showcase Img"
                    layout="fullWidth"
                    placeholder="none"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Leaders;
